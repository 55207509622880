
import Vue from "vue";
import Auth from "@/lib/Auth";

export default Vue.extend({
  name: "UserMenu",

  data: () => ({
    rand: 1,
    userMenu: false,
    active: false,
    dadosUsuario: {} as any,
    fullscreen: false,
    avatar: "",
    userApiEnabled: false,
  }),

  created() {
    this.dadosUsuario = Auth.getUserData();
    this.avatar = sessionStorage.userAvatar;
    this.rand = new Date().getTime();
    const us = Auth.getUser();
    this.userApiEnabled = us.data.apiStatus;
  },
  methods: {
    toggleFullscreen() {
      const doc = window.document as any;
      let docEl = doc.documentElement as any;

      const requestFullScreen =
        docEl.requestFullscreen ||
        docEl.mozRequestFullScreen ||
        docEl.webkitRequestFullScreen ||
        docEl.msRequestFullscreen;
      const cancelFullScreen =
        doc.exitFullscreen ||
        doc.mozCancelFullScreen ||
        doc.webkitExitFullscreen ||
        doc.msExitFullscreen;

      if (
        !doc.fullscreenElement &&
        !doc.mozFullScreenElement &&
        !doc.webkitFullscreenElement &&
        !doc.msFullscreenElement
      ) {
        requestFullScreen.call(docEl);
        this.fullscreen = true;
      } else {
        cancelFullScreen.call(doc);
        this.fullscreen = false;
      }
    },
    logout() {
      this.rand = this.randomNumber();
      Auth.logout();
    },
    randomNumber: function () {
      return Math.floor(Math.random() * (10 - 1 + 1)) + 1;
    },
  },
});
